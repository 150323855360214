// Blog.js
import React from 'react';
import { Container, Grid, Typography, Card, CardContent } from '@mui/material';
import '../styles/Blog.css'

const Blog = () => {
    return (
        <Container className='blog-container'>
            <div className='blog-title-container'>
                <Typography variant="h4" gutterBottom className='blog-title'>
                    Latest Blog Posts
                </Typography>
            </div >
            <Grid container spacing={2}>
                {/* Sample blog posts */}
                {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <Card className='blog-card'>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Blog Post Title
                                </Typography>
                                <Typography variant="body2">
                                    Date: January 1, 2024
                                </Typography>
                                <Typography variant="body1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla commodo efficitur magna vel aliquam.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container >
    );
};

export default Blog;
