// Footer.js
import React from 'react';
import { Container, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#f0f0f0', padding: '20px 0' }}>
            <Container>
                <Typography variant="body2" align="center" color="textSecondary">
                    © {new Date().getFullYear()} Your Website Name
                </Typography>
                <Typography variant="body2" align="center" color="textSecondary">
                    Made with ❤️ by Your Name
                </Typography>
                <Typography variant="body2" align="center" color="textSecondary">
                    <Link color="inherit" href="/privacy-policy">Privacy Policy</Link> | <Link color="inherit" href="/terms-of-service">Terms of Service</Link>
                </Typography>
            </Container>
        </footer>
    );
};

export default Footer;
