import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import About from "./components/About";

export default function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" exact element={<About />} />
          {/* <Route path="/tweets" exact element={<Tweets />} /> */}
        </Routes>
      </div>
    </Router>
  );
}
