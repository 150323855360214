import React from 'react';
import '../styles/Header.css'; // Import the CSS file
import logoSmall from '../assets/Logo-trans-cropped.png'
import { AppBar, Button, ButtonGroup, Toolbar } from '@mui/material';
const Header = () => {
    return (
        <AppBar position="sticky">
            <Toolbar>
                <img
                    src={logoSmall}
                    width="60"
                    height="60"
                    className="d-inline-block align-top"
                    alt="Your Studio's Logo"
                />
                <ButtonGroup variant="text" aria-label="Basic button group">
                    <Button className='nav-button' variant="h6">About Us</Button>
                    <Button className='nav-button' variant="h6">Games</Button>
                    <Button className='nav-button' variant="h6">Contact</Button>
                </ButtonGroup>
            </Toolbar>
        </AppBar >
    );
};

export default Header;
