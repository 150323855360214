import React from 'react';
import '../styles/Hero.css';
import { Button, Typography } from '@mui/material';

const Hero = () => {
    return (
        <div className="hero-section">
            <div className="hero-content-wrapper">
                <div className="hero-text">
                    <Typography variant="h2" gutterBottom className="tagline"> Crafting Worlds, Unleashing Imagination</Typography>
                    <Button variant='contained' className="cta-button">Explore Our Games</Button>
                </div>
            </div>
        </div>
    );
};

export default Hero;