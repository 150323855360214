import React from 'react'
import Header from './Header'
import Hero from './Hero'
import { CssBaseline } from '@mui/material';
import Blog from './Blog';
import Footer from './Footer';

export default function About() {
    return (
        <>
            <Header />
            <CssBaseline />
            <Hero />
            <Blog />
            <Footer />
        </>
    )
}
